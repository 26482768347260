/*
* class AppFunctions
*/

var AppFunctions = function() {

    /*
    * Env variables
    */
    // var $main  = $('#main_wrapper')
    var slides = []

    /*
    * front
    */

    var controlTabs = function()
    {
        let tabsblocks = document.querySelectorAll('[data-tabs]');

        for (let i = 0; i < tabsblocks.length; i++) {
            
            let tabsblock = tabsblocks[i];
            let holdtabs = document.getElementById(tabsblock.dataset.tabs);
            
            let tabs = holdtabs.querySelectorAll('[data-target]');

            for (let i = 0; i < tabs.length; i++) {
                
                let tab    = tabs[i];
                let target = document.getElementById(tab.dataset.target);

                tab.addEventListener('click', function(){
                    let oldtab = holdtabs.querySelector('.tab.active');
                    oldtab.classList.remove('active');
                    tab.classList.add('active');

                    let oldcontent = tabsblock.querySelector('.content-tab.active');
                    oldcontent.classList.remove('active');
                    target.classList.add('active');
                })
            }
        }
    }

    var controlResp = function()
    {
        // Exemple actions pour responsive
        // let togglemenu = document.getElementById('toggle_menu')
        // let menublock  = document.getElementById('resp_menu')
        // let holdmenu   = document.getElementById('hold_resp_menu')

        // var trait = function(event){
        //     var res = ['m','sm','s'];
        //     if( !res.includes(PAtools.getScreenType()) )
        //         return;
         
        //     event.preventDefault()

        //     if( menublock.classList.contains('open') ){
        //         menublock.classList.remove('open')
        //         document.body.classList.remove('overled')
        //     }
        //     else{
        //         menublock.classList.add('open')
        //         document.body.classList.add('overled')
        //     }
        // }

        // menublock.addEventListener('touchstart', trait)
        // menublock.addEventListener('click', trait)

        // holdmenu.addEventListener('click', function(event){
        //     event.stopPropagation()
        // })
        // holdmenu.addEventListener('touchstart', function(event){
        //     event.stopPropagation()
        // })
    }

    var scrollActions = function()
    {
        
    }

    var controlFile = function()
    {
        let inputs   = document.querySelectorAll('.logo-block input');

        for (let index = 0; index < inputs.length; index++) {
            
            let input  = inputs[index];
            let holder = input.parentNode;

            input.addEventListener('change', function(e) {

                if(holder.classList.contains('imported'))
                    return;
                
                let file   = input.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let img  = reader.result;

                    for (let i = 0; i < inputs.length; i++) {
            
                        let input  = inputs[i];
                        let holder = input.parentNode;
                        
                        let html = document.createElement('img');
                        html.src = img;
                        holder.appendChild(html);
                        holder.classList.add('imported');
                        input.disabled = true;
                    }
                    
                };
                reader.onerror = function (error) {
                    alert('Désolé, une erreur est survenue');
                };
            });
        }
    }

    return {

        /*
        * Main class initializer
        */

        init: function() {
            AppFunctions.initComponents();

            while (pagefunctions.length){
                pagefunctions.shift().call();
            }
        
            if(typeof launchFunction == 'function')
                launchFunction()
        },

        /*
        * Initializes components
        */

        initComponents: function() {
            
            // controlOverlay()
            scrollActions()
            controlResp()
            controlTabs()
            controlFile()
            // controlSlides()

            // loadOverlay({'overlay':'contactform'})

            // $(window).resize(function() {
                // controlResp()
            // })
        },

        initForm: function(id) {

            return ajaxForm(id)
        },

        loadOverlay: function(slug) {
            loadOverlay(slug)
        }
    };

}();

(function() {

    // AppFunctions.init();

})();